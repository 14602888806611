<template>
  <v-main class="pa-5">
    <v-row>
      <v-col sm="3"></v-col>
      <v-col align="center" align-self="center" sm="6">
        <h1>Settings</h1>
        <ACWithCustom
          class="pt-5 mr-5"
          label="Channel"
          hint="When a channel is defined, sounds are only played back in other clients with the same channel. The empty value is just a normal channel (not a wildcard)."
          persistent-hint
          :channelService="channelService"
          @change="onChannelChanged"
        ></ACWithCustom>
        <v-text-field
          label="x days = new"
          hint="Jingles that have been added in the last <x> days will be visually highlighted as 'new'"
          persistent-hint
          v-model="newThresholdLocal"
          type="number"
          @change="onNewThresholdChanged"
        ></v-text-field>
        <v-text-field
          ref="buttonsPerRowField"
          label="Buttons per row"
          hint="Shows at most this many jingle buttons per row (0=auto)"
          persistent-hint
          v-model="itemsPerRowLocal"
          type="number"
          :rules="itemsPerRowRules"
          @change="onItemsPerRowChanged"
        ></v-text-field>
      </v-col>
      <v-col sm="3"></v-col>
    </v-row>
  </v-main>
</template>

<script>
import ACWithCustom from "@/components/ACWithCustom";
export default {
  name: "Settings",
  data: () => ({
    newThresholdLocal: 7,
    itemsPerRowLocal: 6,
    itemsPerRowRules: [
      (value) => {
        return value < 0 || value > 12
          ? "Value must be between 0 and 12"
          : true;
      },
    ],
  }),
  methods: {
    onChannelChanged: function (newValue) {
      this.$emit("channelChanged", newValue);
    },
    onNewThresholdChanged: function (newValue) {
      this.$emit("newThresholdChanged", Number.parseInt(newValue, 10));
    },
    onItemsPerRowChanged: function (newValue) {
      let hasError = false;
      this.itemsPerRowRules.forEach((fn) => {
        let output = fn(newValue);
        if (!output || typeof output === "string") {
          hasError = true;
        }
      });
      if (hasError) {
        return;
      }
      this.$emit("itemsPerRowChanged", Number.parseInt(newValue, 10));
    },
  },
  created() {
    this.newThresholdLocal = this.newThreshold;
    this.itemsPerRowLocal = this.itemsPerRow;
  },
  watch: {
    newThreshold: function (newValue) {
      this.newThresholdLocal = newValue;
    },
    itemsPerRow: function (newValue) {
      this.itemsPerRowLocal = newValue;
    },
  },
  props: {
    settingsService: {
      type: Object,
      required: true,
    },
    channelService: {
      type: Object,
      required: true,
    },
    newThreshold: {
      type: Number,
      default: 7,
    },
    itemsPerRow: {
      type: Number,
      default: 6,
    },
  },
  components: {
    ACWithCustom,
  },
};
</script>
