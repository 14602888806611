<template>
  <v-autocomplete
    v-model="value"
    :items="items"
    :label="label"
    :loading="loading"
    :search-input.sync="search"
    :hint="hint"
    :persistent-hint="persistentHint"
    hide-no-data
    @change="emitValueChange"
    @keydown="onKeyDown"
    clearable
  ></v-autocomplete>
</template>

<script>
export default {
  name: "ACWithCustom",
  props: {
    label: {
      type: String,
      required: true,
    },
    hint: {
      type: String,
      default: "",
    },
    persistentHint: {
      type: Boolean,
      default: false,
    },
    channelService: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    value: "",
    loading: false,
    search: null,
    items: [],
    timerId: null,
  }),
  watch: {
    search(val) {
      val &&
        val.length >= 3 &&
        val !== this.select &&
        this.querySelections(val);
    },
  },
  methods: {
    onKeyDown: function (evt) {
      // When pressing "Enter", the autocomplete component of "Vuetify" does not
      // trigger a change event if no item is available in the completions. As
      // we want to support custom values, this event-handler ensures we also
      // accept any arbitrary value on "Enter" before we got any completions.
      if (evt.code === "Enter") {
        this.emitValueChange(evt.srcElement.value);
      }
    },
    emitValueChange: function (newValue) {
      this.$emit("change", newValue);
    },
    async execSearch(value) {
      if (value && value.length > 3 && value !== this.value) {
        this.loading = true;
        let result = await this.channelService.search(value);
        if (result.length === 0) {
          this.items = [value];
        } else {
          result = result.map((item) => {
            return item.name;
          });
          this.items = result;
        }
        this.loading = false;
      }
    },
    /* istanbul ignore next */
    async querySelections(value) {
      if (this.timerId !== null) {
        window.clearTimeout(this.timerId);
        this.timerId = null;
      }
      this.timerId = window.setTimeout(this.execSearch, 300, value);
    },
  },
};
</script>
